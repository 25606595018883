import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Grid,
  List,
  ListItem,
  Breadcrumbs,
  Link,
  useMediaQuery
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4, 23),
    },
    background: theme.palette.background.footer,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoContainer: {
    width: 55,
    height: 65,
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  menuListContainer: {
    padding: '0 !important',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    margin: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'white',
  },
  divider: {
    width: '100%',
  },
  navLink: {
    color: 'rgba(255,255,255,.6)',
  },
}));

const Footer = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  const MenuGroup = props => {
    const { item } = props;
    return (
      <List disablePadding className={classes.menuItem}>
        <ListItem disableGutters className={classes.menuGroupItem}>
          <Typography variant="body2" className={classes.menuGroupTitle}>
            {item.groupTitle}
          </Typography>
        </ListItem>
        {item.pages.map((page, i) => (
          <ListItem disableGutters key={i} className={classes.menuGroupItem}>
            <Typography
              variant="body2"
              component={'a'}
              href={page.href}
              className={clsx(classes.navLink, 'submenu-item')}
            >
              {page.title}
            </Typography>
          </ListItem>
        ))}
      </List>
    );
  };

  const Rechtstexte = () => {
    return (
      <div className={classes.menu}>
        <div>
          <MenuGroup item={rechtstexte} />
        </div>
      </div>
      );
    };
  
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'), { defaultMatches : true });
  const justifyGrid1 = isMdDown ? 'center' : 'flex-start';
  const justifyGrid3 = isMdDown ? 'center' : 'flex-end';

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={3}>

          <Grid item xs={12} md={4} style={{display:'flex'}} alignItems="center" justify={justifyGrid1}>
            <List disablePadding>
              <ListItem disableGutters className={classes.logoContainerItem}>
                <div className={classes.logoContainer}>
                  <a href="/" title="tenbi">
                    <Image
                      className={classes.logoImage}
                      src={require('assets/images/logos/federIconWhite.svg')}
                      alt="thefront"
                      lazy={true}
                    />
                  </a>
                </div>
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={4} style={{display:'flex'}} alignItems="center" justify="center" >
            <Breadcrumbs separator="-" aria-label="breadcrumb" >
              <Link color="inherit" href="/impressum" >
                Impressum
              </Link>
              <Link color="inherit" href="/datenschutz" >
                Datenschutz
              </Link>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={12} md={4} style={{display:'flex'}} alignItems="center" justify={justifyGrid3} >
            <IconButton className={classes.socialIcon} href="https://www.facebook.com/tenbi.de" target="blank">
              <FacebookIcon className={classes.icon} />
            </IconButton>
            <IconButton className={classes.socialIcon} href="https://www.instagram.com/tenbi_/" target="blank">
              <InstagramIcon className={classes.icon} />
            </IconButton>
          </Grid>

        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
